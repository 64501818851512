.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.ant-picker-ok>button {
  color: #fff;
  background-color: #1677ff;
}

.ant-btn-primary {
  background: #1677ff;
}

/* Form Field Time Picker */
.form-field-timepicker .ant-input-number {
  height: 38px;
}

.form-field-timepicker .ant-input-number-input-wrap {
  height: 100%;
}

.form-field-timepicker .ant-input-number-input {
  height: 100%;
  text-align: center;
}

/* Carousel de antd */
.ant-carousel .slick-dots li button {
  background: red;
  height: 8px;
}

.ant-carousel .slick-dots li.slick-active button {
  background: blue;
}

/* Switch de antd */
.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

/* Para permiso médico: líneas diagonales verdes */
.bg-medical-sick-leave {
  background-image: linear-gradient(to bottom, rgba(59, 130, 246, 0.4) 50%, transparent 50%);
  background-size: 100% 4px;
}

/* Para vacaciones: líneas horizontales azules */
.bg-holiday {
  background-image: linear-gradient(45deg, rgba(16, 185, 129, 0.4) 25%, transparent 25%, transparent 50%, rgba(16, 185, 129, 0.4) 50%, rgba(16, 185, 129, 0.4) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

/* Para permiso de paternidad: puntos morados */
.bg-paternity-leave {
  background-image: radial-gradient(circle, rgba(139, 92, 246, 0.5) 20%, transparent 20%);
  background-size: 10px 10px;
}

/* Para permiso de matrimonio: líneas verticales rosas */
.bg-marriage-permit {
  background-image: linear-gradient(to right, rgba(244, 114, 182, 0.5) 50%, transparent 50%);
  background-size: 4px 100%;
}

/* Para fichaje sin utilizar la aplicación: líneas horizontales azul cyan y fondo gris */
.bg-clock-in-without-app {
  background-image: linear-gradient(to bottom, rgb(12, 176, 230) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Para los que no fichan (porque no quieren): líneas horizontales rojas y fondo gris */
.bg-without-clock-in {
  background-image: linear-gradient(to bottom, rgba(255, 0, 0, 0.5) 1px, transparent 1px);
  background-size: 20px 20px;
}

/* Para los días de guardia: barras en diagonal azules */
.bg-guard {
  background-image: linear-gradient(135deg, rgba(16, 185, 129, 0.4) 25%, transparent 25%, transparent 50%, rgba(16, 185, 129, 0.4) 50%, rgba(16, 185, 129, 0.4) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

/* Para los días de hospitalización de familiar: barras en vertical azules */
.bg-family-hospitalization {
  background-image: linear-gradient(90deg, rgba(16, 185, 129, 0.4) 25%, transparent 25%, transparent 50%, rgba(16, 185, 129, 0.4) 50%, rgba(16, 185, 129, 0.4) 75%, transparent 75%, transparent);
  background-size: 10px 10px;
}

/* Para los días de fichaje manual con hoja de papel: barras azules en horizontal sobre fondo azul */
.bg-signing-on-sheet-of-paper {
  background-image: linear-gradient(0deg, rgba(65, 105, 225, 0.6) 25%, transparent 25%, transparent 50%, rgba(65, 105, 225, 0.6) 50%, rgba(65, 105, 225, 0.6) 75%, transparent 75%, transparent);
  background-size: 20px 20px;
}

/* Para los días de vacaciones del año anterior: barras rojas en diagonal sobre fondo azul */
.bg-holiday-previous-year {
  background-image: linear-gradient(45deg, rgba(255, 99, 71, 0.6) 25%, transparent 25%, transparent 50%, rgba(255, 99, 71, 0.6) 50%, rgba(255, 99, 71, 0.6) 75%, transparent 75%, transparent);
  background-size: 15px 15px;
}

/* Para los días de excedencia: psicodelico */
.bg-work-leave {
  background-image: linear-gradient(60deg, rgba(0, 128, 128, 0.5) 20%, transparent 20%, transparent 40%, rgba(238, 130, 238, 0.5) 40%, rgba(238, 130, 238, 0.5) 60%, transparent 60%, transparent 80%, rgba(0, 128, 128, 0.5) 80%);
  background-size: 20px 20px;
}

/* Para los días de fallecimiento de familiar: psicodelico */
.bg-family-death {
  background-image: linear-gradient(45deg, rgba(105, 105, 105, 0.5) 15%, transparent 15%, transparent 35%, rgba(169, 169, 169, 0.5) 35%, rgba(169, 169, 169, 0.5) 55%, transparent 55%, transparent 75%, rgba(105, 105, 105, 0.5) 75%);
  background-size: 25px 25px;
}